<template>
  <CAlert color="danger" v-if="errormessage">
    <small>Fehler {{errormessage.code}} - {{errormessage.message}}</small>
    <div v-if="errormessage.errors">
      <ul v-if="errormessage.errors.children">
        <!-- eslint-disable-next-line -->
        <li v-for="(item, key, index) in errormessage.errors.children" v-bind:key="index" v-if="item.errors">
          <small>Feld: {{key}}</small>
          <ul v-if="item.errors">
            <li v-for="error in item.errors" v-bind:key="error">
              {{error}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </CAlert>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    errormessage: [Error, Object]
  },
  computed: {
  }
}
</script>
